export enum PageRoutes {
    Login = '/auth/login',
    GroupDashboard = '/pages/dashboard',
    GroupSales = '/pages/sales',
    Customers = '/pages/sales/customers',
    CustomersAdd = '/pages/sales/customers/add',
    Orders = '/pages/sales/orders',
    OrdersAdd = '/pages/sales/orders/add',
    ProductCatalog = '/pages/sales/catalog',
    GroupCpo = '/pages/cpo',
    Chargers = '/pages/cpo/chargers',
    ChargersAdd = '/pages/cpo/chargers/add',
    Transactions = '/pages/cpo/transactions',
    Logs = '/pages/cpo/logs',
    GroupCpoOperations = '/pages/cpo/operations',
    Commands = '/pages/cpo/operations/commands',
    Stickers = '/pages/cpo/operations/stickers',
    Terminal = '/pages/cpo/operations/terminal',
    Diagnostics = '/pages/cpo/operations/diagnostics',
    Firmware = '/pages/cpo/operations/firmware',
    Reservations = '/pages/cpo/reservations',
    ChargePointOperators = '/pages/cpo/chargepointoperator',
    ChargePointOperatorsAdd = '/pages/cpo/chargepointoperator/add',
    GroupFactory = '/pages/factory',
    FactoryOrders = '/pages/factory/orders',
    FactoryOrdersAdd = '/pages/factory/orders/add',
    FactoryOrdersGraph = '/pages/factory/orders/graph',
    FactoryOrdersSplit = '/pages/factory/orders/split',
    FactoryOrdersOrdered = '/pages/factory/orders/ordered',
    Factories = '/pages/factory/factories',
    FactoriesAdd = '/pages/factory/factories/add',
    FactoryShipments = '/pages/factory/shipments',
    FactoryShipmentsAdd = '/pages/factory/shipments/add',
    FactoryOverview = '/pages/factory/overview',
    FactoryOverviewDetailParts = '/pages/factory/overview/details/parts',
    GroupStock = '/pages/stock',
    Stock = '/pages/stock/stocks',
    StockAdd = '/pages/stock/stocks/add',
    StockMove = "/pages/stock/stocks/move",
    StockOrders = '/pages/stock/stockorders',
    StockOrdersAdd = '/pages/stock/stockorders/add',
    Kusters = '/pages/stock/kusters',
    GroupEmp = '/pages/emp',
    Tags = '/pages/emp/tags',
    TagsAdd = '/pages/emp/tags/add',
    TagGroups = '/pages/emp/taggroups',
    TagGroupsAdd = '/pages/emp/taggroups/add',
    Usage = '/pages/emp/usage',
    Billing = '/pages/emp/billing',
    Pricing = '/pages/emp/pricing',
    PricingAdd = '/pages/emp/pricing/add',
    GroupSecurity = '/pages/security',
    Tokens = '/pages/security/tokens',
    TokensAdd = '/pages/security/tokens/add',
    Roles = '/pages/security/roles',
    RolesAdd = '/pages/security/roles/add',
    Actions = '/pages/security/actions',
    ActionsAdd = '/pages/security/actions/add',
    GroupComponents = '/pages/components',
    Suppliers = '/pages/components/suppliers',
    SuppliersAdd = '/pages/components/suppliers/add',
    GroupComponentsOperations = '/pages/components/operations',
    ComponentsChangeProposal = '/pages/components/operations/changeproposal',
    ComponentsBom = '/pages/components/operations/bom',
    ComponentsProcurement = '/pages/components/operations/procurement',
    ComponentsWhereUsed = '/pages/components/operations/whereused',
    ComponentsQueries = '/pages/components/operations/query',
    ComponentsCompare = '/pages/components/operations/compare',
    ComponentsCompareView = '/pages/components/operations/compare/view',
    ComponentsImport = '/pages/components/operations/import',
    Parts = '/pages/components/parts',
    PartsAdd = '/pages/components/parts/add',
    Documents = '/pages/components/documents',
    DocumentsAdd = '/pages/components/documents/add',
    GroupServices = '/pages/service',
    ChargerService = '/pages/service/charger',
    GroupPreferences = '/pages/preferences',
    GroupDeveloper = '/pages/developer',
    DeveloperVersion = '/pages/developer/version'
}

export const ROUTES_ALWAYS_SHOWN = [
    PageRoutes.GroupDashboard.toString(),
    PageRoutes.GroupPreferences.toString()
];