export const environment = {
	production: false,
	build: {
		id: "1736239339",
		url: "https://gitlab.com/lumosa/portal/portal/-/pipelines/1736239339",
		version: "0.0.90bcd28e",
		env: "acceptance"
	},
	microsoftAuth: {
		clientId: "6a0449c5-824a-41c8-a368-05eb92bd7f82",
		authority: "https://login.microsoftonline.com/a527d83e-25fa-440f-8590-87e1a866a57a"
	},
	cpo: {
		url: "https://cpo.acceptance.lumosa.eu"
	},
	api: {
		url: "https://api.acceptance.lumosa.eu"
	},
	mailto: {
		finance: "softwareteam@lumosa.eu",
	},
	grafana: {
		url: "https://lumosa.grafana.net/d/ZekywLF7k/info?orgId=1"
	},
}
